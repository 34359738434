import { Button, NativeSelect, Text, TextInput, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { CompleteSurveyDocument } from '../../graphql/generated';
import { client } from '../../lib/apollo-client';

const Inner = styled.div`
  background: #f4f5f6;
  padding: 20px 20px 25px;
  margin: 20px 0px 30px;
  border-radius: 5px;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

interface FormInterface {
  q1: string; // Hver er tilgangur þinn af viðskiptum við mintum.is?
  q2: string; // Eru viðskiptin í þágu þriðja aðila?
  q2a: string; // Nafn
  q2b: string; // Heimilisfang
  q2c: string; // Sími
  q2d: string; // Kennitala (ef við á)
  q3: string; // Hver eru mánaðarlegar tekjur þínar fyrir skatta?
  q4: string; // Hver er uppruni þeirra fjármuna sem þú munt nota hjá mintum.is?
  q4a: string; // q4=Annad, Hvað?
  q5: string; // Munt þú nota þjónustu ISKT til að móttaka eða senda reglulega erlendar greiðslur
  q5a: string; // Í hvaða tilgangi?
}

interface KycAdvancedAnswers {
  businessPurpose: string;
  isThirdPartyBusiness: boolean;
  thirdPartyName?: string;
  thirdPartyAddress?: string;
  thirdPartyPhone?: string;
  thirdPartyNationalId?: string;
  salaryBeforeTax: string;
  fundsOriginatedFrom: string;
  fundsOriginatedFromOther: string;
  usedForInternationalTransfers: boolean;
  internationalTransfersReason: string;
}

export function AdvancedRiskWidget() {
  const requiredMessage = 'Þú þarft að velja svar';
  const requiredExtraQuestinonMessage = 'Þú þarft að svara spurningunni';
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    q1: yup.string().required(requiredMessage),
    q2: yup.string().required(requiredMessage),
    q2a: yup.string().when('q2', {
      is: 'Já',
      then: yup.string().required(requiredExtraQuestinonMessage),
    }),
    q2b: yup.string().when('q2', {
      is: 'Já',
      then: yup.string().required(requiredExtraQuestinonMessage),
    }),
    q2c: yup.string().when('q2', {
      is: 'Já',
      then: yup.string().required(requiredExtraQuestinonMessage),
    }),
    q2d: yup.string().when('q2', {
      is: 'Já',
      then: yup.string(),
    }),
    q3: yup.string().required(requiredMessage),
    q4: yup.string().required(requiredMessage),
    q4a: yup.string().when('q4', {
      is: 'Já',
      then: yup.string().required(requiredExtraQuestinonMessage),
    }),
    q5: yup.string().required(requiredMessage),
    q5a: yup.string().when('q5', {
      is: 'Já',
      then: yup.string().required(requiredExtraQuestinonMessage),
    }),
  });

  const form = useForm<FormInterface>({
    validate: yupResolver(schema),
    initialValues: {
      q1: '',
      q2: '',
      q2a: '',
      q2b: '',
      q2c: '',
      q2d: '',
      q3: '',
      q4: '',
      q4a: '',
      q5: '',
      q5a: '',
    },
  });

  const onSubmit = async () => {
    const answers: KycAdvancedAnswers & { raw: any } = {
      businessPurpose: form.values.q1,
      isThirdPartyBusiness: form.values.q2 === 'Já',
      thirdPartyName: form.values.q2a,
      thirdPartyAddress: form.values.q2b,
      thirdPartyPhone: form.values.q2c,
      thirdPartyNationalId: form.values.q2d,
      salaryBeforeTax: form.values.q3,
      fundsOriginatedFrom: form.values.q4,
      fundsOriginatedFromOther: form.values.q4a,
      usedForInternationalTransfers: form.values.q5 === 'Já',
      internationalTransfersReason: form.values.q5a,
      raw: form.values,
    };

    try {
      setLoading(true);
      await client.mutate({
        mutation: CompleteSurveyDocument,
        variables: {
          type: 'ADVANCED_REQUIRED',
          answers,
        },
      });
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={form.onSubmit(() => onSubmit())}>
      <div
        style={{
          padding: 32,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          height: '100%',
          margin: '0 auto',
        }}>
        <Title order={1} align="center" sx={{ marginBottom: 16 }}>
          Áreiðanleikakönnun (framhald)
        </Title>
        <Text color="dimmed" size="md" align="center" sx={{ marginBottom: 32 }}>
          Rafmyntasjóður Íslands er skyldugur á grundvelli laga nr. 140/2018 að
          búa yfir ákveðnum upplýsingum um viðskiptavini sína og biðjum við þig
          hér með að svara nokkrum spurningum.
        </Text>
        <InputWrapper>
          <TextInput
            label="Hver er tilgangur þinn af viðskiptum við mintum.is?"
            value={form.values.q1}
            error={form.errors.q1}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              form.setFieldValue('q1', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
          <NativeSelect
            data={['Já', 'Nei']}
            placeholder="Veldu svar"
            label="Eru viðskiptin í þágu þriðja aðila?"
            error={form.errors.q2}
            value={form.values.q2}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              form.setFieldValue('q2', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
          {form.values.q2 === 'Já' && (
            <Inner>
              <Text
                size="md"
                align="left"
                sx={{
                  marginBottom: 8,
                  marginTop: 16,
                  fontWeight: 500,
                  color: '#212529',
                }}>
                Í þágu hverns eru viðskiptin?
              </Text>
              <TextInput
                label="Nafn"
                value={form.values.q2a}
                error={form.errors.q2a}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q2a', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
              <TextInput
                label="Heimilisfang"
                value={form.values.q2b}
                error={form.errors.q2b}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q2b', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
              <TextInput
                label="Sími"
                value={form.values.q2c}
                error={form.errors.q2c}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q2c', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
              <TextInput
                label="Kennitala (ef við á)"
                value={form.values.q2d}
                error={form.errors.q2d}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q2d', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
            </Inner>
          )}
          <NativeSelect
            data={[
              'Undir 500.000 kr.',
              '500.000 - 750.000 kr.',
              '750.000 - 1.000.000 kr.',
              '1.000.000 kr. - 1.500.000 kr',
              'Yfir 1.500.000 kr.',
            ]}
            placeholder="Veldu svar"
            label="Hver eru mánaðarlegar tekjur þínar fyrir skatta?"
            error={form.errors.q3}
            value={form.values.q3}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              form.setFieldValue('q3', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
          <NativeSelect
            data={[
              'Tekjur eða laun',
              'Arður',
              'Sparnaður',
              'Arfur',
              'Lán',
              'Erlent fjármagn',
              'Annað',
            ]}
            placeholder="Veldu svar"
            label="Hver er uppruni þeirra fjármuna sem þú munt nota hjá mintum.is?"
            error={form.errors.q4}
            value={form.values.q4}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              form.setFieldValue('q4', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
          {form.values.q4 === 'Annað' && (
            <Inner>
              <TextInput
                label="Hvað?"
                value={form.values.q4a}
                error={form.errors.q4a}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q4a', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
            </Inner>
          )}
          <NativeSelect
            data={['Já', 'Nei']}
            placeholder="Veldu svar"
            label="Munt þú nota þjónustu ISKT til að móttaka eða senda reglulega erlendar greiðslur"
            error={form.errors.q5}
            value={form.values.q5}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              form.setFieldValue('q5', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
          {form.values.q5 === 'Já' && (
            <Inner>
              <TextInput
                label="Í hvaða tilgangi?"
                value={form.values.q5a}
                error={form.errors.q5a}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q5a', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
            </Inner>
          )}
        </InputWrapper>
        <Button type="submit" size="md" loading={loading}>
          Senda svör
        </Button>
      </div>
    </form>
  );
}
