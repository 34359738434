import { Button, Loader, TextInput } from '@mantine/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MultiFactorType } from '../../graphql/generated';
import { MfaReason, MultiFactorStatus } from '../../lib/auth-store';

const typeLabels = {
  [MultiFactorType.App]: 'App',
  [MultiFactorType.Email]: 'Tölvupóstur',
  [MultiFactorType.Sms]: 'SMS',
  [MultiFactorType.SecurityKey]: 'Öryggislykill',
};

export function MultiFactorInput({
  auth,
  type,
  autoFocus,
}: {
  auth: any;
  type: MultiFactorType;
  autoFocus?: boolean;
}) {
  // const auth = useAuthStore();
  const delayRef = useRef<NodeJS.Timeout>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [delay, setDelay] = useState(0);
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const isVerified = auth.mfa?.[type] === MultiFactorStatus.VERIFIED;
  const isSendable = [MultiFactorType.Sms, MultiFactorType.Email].includes(
    type
  );
  const stuff = useRef(Math.random());

  const maxChars = type === MultiFactorType.Email ? 8 : 6;

  const onSendCode = useCallback(async () => {
    if (isSendable) {
      setSending(true);
      try {
        if (
          await auth.actions.sendMfa(
            type as MultiFactorType.Sms,
            MfaReason.LOGIN
          )
        ) {
          setDelay(10);
          inputRef.current?.focus();
        }
      } catch (err) {
        console.log('error sending code', err);
        // noop
      }
      setSending(false);
    }
  }, [auth.actions, isSendable, type]);

  const onSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    setLoading(true);
    setError(false);
    let wasError;
    try {
      const res = await auth.actions.refreshTokens({
        logoutOnError: false,
        otp: {
          [type]: code,
        },
      });
      if (res && res.mfa[type] === MultiFactorStatus.REQUIRED) {
        throw new Error('Invalid code');
      }
    } catch (err) {
      wasError = true;
    }
    await new Promise((r) => setTimeout(r, 330));
    setLoading(false);
    setCode('');
    if (wasError) {
      setError(true);
      inputRef.current?.focus();
    }
    return false;
  };

  useEffect(() => {
    if (delay > 0) {
      delayRef.current = setInterval(() => {
        setDelay(delay - 1);
      }, 1000);
    }
    return () => delayRef.current && clearInterval(delayRef.current);
  }, [delay]);

  useEffect(() => {
    if (code.length > 0) {
      setError(false);
    }
    if (code.trim().length === maxChars) {
      onSubmit();
    }
  }, [code, maxChars]);

  return (
    <form onSubmit={onSubmit} style={{ marginBottom: 8 }}>
      <TextInput
        ref={inputRef}
        disabled={loading || isVerified}
        label={typeLabels[type]}
        value={code}
        autoFocus={autoFocus}
        onChange={(e) =>
          setCode(e.currentTarget.value.replace(/\D/g, '').substr(0, maxChars))
        }
        error={error ? 'Ógildur kóði, reyndu aftur.' : undefined}
        type="tel"
        placeholder={isVerified ? '(staðfest)' : undefined}
        maxLength={maxChars}
        sx={{
          input: { fontWeight: 600 },
          label: {
            textTransform: 'uppercase',
            fontSize: 13,
            letterSpacing: '0.04em',
            fontWeight: 600,
          },
        }}
        rightSectionWidth={80}
        rightSection={
          isSendable &&
          !isVerified && (
            <Button
              compact
              sx={{ width: 70, minHeight: 28 }}
              size="xs"
              variant="light"
              onClick={onSendCode}
              disabled={sending || delay > 0}>
              {sending ? (
                <Loader size="xs" color="gray" />
              ) : delay > 0 ? (
                delay.toString()
              ) : (
                'Senda'
              )}
            </Button>
          )
        }
      />
    </form>
  );
}
