export const HightRiskCountries = [
  'Afganistan',
  'Albanía',
  'Alþýðulýðveldið Kórea',
  'Barbados',
  'Búrkína Fasó',
  'Cayman eyjar',
  'Filippseyjar',
  'Gíbraltar',
  'Haítí',
  'Íran',
  'Jamaíka',
  'Jemen',
  'Jórdanía',
  'Kambódía',
  'Malí',
  'Marokkó',
  'Mjanmar/Búrma',
  'Níkaragva',
  'Pakistan',
  'Panama',
  'Sameinuðu arabísku furstadæmin',
  'Senegal',
  'Simbabve',
  'Suður-Súdan',
  'Sýrland',
  'Trinidad og Tóbagó',
  'Tyrkland',
  'Úganda',
  'Vanúatú',
];
