import { Text, Title } from '@mantine/core';
import React from 'react';
import styled from 'styled-components';
import type { User } from '../../graphql/generated';
import { formatNationalId } from '../../lib/format-national-id';
import { UserAvatar } from '../user-avatar/user-avatar';

const Host = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export function UserProfileWidget({
  user,
}: {
  user?: Partial<Pick<User, 'fullName' | 'avatarUrl' | 'nationalId'>>;
}) {
  if (!user) {
    return null;
  }

  return (
    <Host>
      <UserAvatar
        size={128}
        sx={{ marginBottom: 16 }}
        fullName={user.fullName}
        avatarUrl={user.avatarUrl}
      />
      <Title order={3}>{user.fullName}</Title>
      <Text size="xl" color="dimmed">
        {formatNationalId(user.nationalId)}
      </Text>
    </Host>
  );
}
