import { Button, NativeSelect, Text, TextInput, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { CompleteSurveyDocument } from '../../graphql/generated';
import { client } from '../../lib/apollo-client';
import { HightRiskCountries } from './high-risk-country-list';

const Inner = styled.div`
  background: #f4f5f6;
  padding: 20px 20px 25px;
  margin: 20px 0px 30px;
  border-radius: 5px;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

interface FormInterface {
  q1: string;
  q1a: string;
  q2: string;
  q2a: string;
  q2b: string;
  q3: string;
  q3a: string;
  q4: string;
  q4a: string;
  q5: string;
  q5a: string;
  q6: string;
}

interface KycBasicAnswers {
  isEmployer: boolean;
  employerName?: string;
  isTaxedInDifferentCountry: boolean;
  taxCountry?: string;
  taxIdentificationNumber?: string;
  isPoliticallyRelated: boolean;
  politicallyRelatedNames?: string;
  isRelatedToHighRiskCountry: boolean;
  highRiskCountry?: string;
  isSentencedForFinance: boolean;
  financeSentenceDescription?: string;
  mintumTurnoverAmount: string;
}

export function RiskWidget() {
  const requiredMessage = 'Þú þarft að velja svar';
  const requiredExtraQuestinonMessage = 'Þú þarft að svara spurningunni';
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    q1: yup.string().required(requiredMessage),
    q1a: yup.string().when('q1', {
      is: 'Já',
      then: yup.string().required(requiredExtraQuestinonMessage),
    }),
    q2: yup.string().required(requiredMessage),
    q2a: yup.string().when('q2', {
      is: 'Já',
      then: yup.string().required(requiredExtraQuestinonMessage),
    }),
    q2b: yup.string().when('q2', {
      is: 'Já',
      then: yup.string().required(requiredExtraQuestinonMessage),
    }),
    q3: yup.string().required(requiredMessage),
    q3a: yup.string().when('q3', {
      is: 'Já',
      then: yup.string().required(requiredExtraQuestinonMessage),
    }),
    q4: yup.string().required(requiredMessage),
    q4a: yup.string().when('q4', {
      is: 'Já',
      then: yup.string().required(requiredExtraQuestinonMessage),
    }),
    q5: yup.string().required(requiredMessage),
    q5a: yup.string().when('q5', {
      is: 'Já',
      then: yup.string().required(requiredMessage),
    }),
    q6: yup.string().required(requiredMessage),
  });

  const form = useForm<FormInterface>({
    validate: yupResolver(schema),
    initialValues: {
      q1: '',
      q1a: '',
      q2: '',
      q2a: '',
      q2b: '',
      q3: '',
      q3a: '',
      q4: '',
      q4a: '',
      q5: '',
      q5a: '',
      q6: '',
    },
  });

  const onSubmit = async () => {
    const answers: KycBasicAnswers & { raw: any } = {
      isEmployer: form.values.q1 === 'Já',
      employerName: form.values.q1a,
      isTaxedInDifferentCountry: form.values.q2 === 'Já',
      taxCountry: form.values.q2a,
      taxIdentificationNumber: form.values.q2b,
      isPoliticallyRelated: form.values.q3 === 'Já',
      politicallyRelatedNames: form.values.q3a,
      isSentencedForFinance: form.values.q4 === 'Já',
      financeSentenceDescription: form.values.q4a,
      isRelatedToHighRiskCountry: form.values.q5 === 'Já',
      highRiskCountry: form.values.q5a,
      mintumTurnoverAmount: form.values.q6,
      raw: form.values,
    };

    try {
      setLoading(true);
      await client.mutate({
        mutation: CompleteSurveyDocument,
        variables: {
          type: 'BASIC_REQUIRED',
          answers,
        },
      });
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={form.onSubmit(() => onSubmit())}>
      <div
        style={{
          padding: 32,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          height: '100%',
          margin: '0 auto',
        }}>
        <Title order={1} align="center" sx={{ marginBottom: 16 }}>
          Áreiðanleikakönnun
        </Title>
        <Text color="dimmed" size="md" align="center" sx={{ marginBottom: 32 }}>
          Rafmyntasjóður Íslands er skyldugur á grundvelli laga nr. 140/2018 að
          búa yfir ákveðnum upplýsingum um viðskiptavini sína og biðjum við þig
          hér með að svara nokkrum spurningum.
        </Text>
        <InputWrapper>
          <NativeSelect
            data={['Já', 'Nei']}
            placeholder="Veldu svar"
            label="Ert þú í starfi?"
            error={form.errors.q1}
            value={form.values.q1}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              form.setFieldValue('q1', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
          {form.values.q1 === 'Já' && (
            <Inner>
              <TextInput
                label="Við hvað starfar þú?"
                value={form.values.q1a}
                error={form.errors.q1a}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q1a', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
            </Inner>
          )}
          <NativeSelect
            data={['Já', 'Nei']}
            placeholder="Veldu svar"
            label="Ber þér að greiða skatta í öðru landi en Íslandi?"
            error={form.errors.q2}
            value={form.values.q2}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              form.setFieldValue('q2', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
          {form.values.q2 === 'Já' && (
            <Inner>
              <TextInput
                label="Í hvaða landi"
                value={form.values.q2a}
                error={form.errors.q2a}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q2a', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
              <TextInput
                label="Skattkennitala (TIN)"
                value={form.values.q2b}
                error={form.errors.q2b}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q2b', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
            </Inner>
          )}
          <NativeSelect
            data={['Já', 'Nei']}
            placeholder="Veldu svar"
            label="Stjórnmálatengsl: Ert þú, náinn fjölskyldumeðlimur eða náinn samstarfsmaður háttsettur í opinberri þjónustu?"
            error={form.errors.q3}
            value={form.values.q3}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              form.setFieldValue('q3', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
          {form.values.q3 === 'Já' && (
            <Inner>
              <TextInput
                label="Vinsamlegast tilgreinið hver sá einstaklingur er?"
                value={form.values.q3a}
                error={form.errors.q3a}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q3a', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
            </Inner>
          )}
          <NativeSelect
            data={['Já', 'Nei']}
            placeholder="Veldu svar"
            label="Hefur þú verið dæmdur fyrir refsiverða háttsemi sem snýr að peningaþvætti og/eða fjármögnun hryðjuverka?"
            error={form.errors.q4}
            value={form.values.q4}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              form.setFieldValue('q4', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
          {form.values.q4 === 'Já' && (
            <Inner>
              <TextInput
                label="Geturu lýst nánar háttseminni?"
                value={form.values.q4a}
                error={form.errors.q4a}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  form.setFieldValue('q4a', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
            </Inner>
          )}
          <NativeSelect
            data={['Já', 'Nei']}
            placeholder="Veldu svar"
            label={
              <>
                Hefur þú tengsl við áhættusöm og ósamvinnuþýð ríki{' '}
                <a
                  href="https://www.fme.is/eftirlit/eftirlit-med-adgerdum-gegn-peningathvaetti-og-fjarmognun-hrydjuverka/ahaettusom-og-osamvinnuthyd-riki/"
                  target="_blank"
                  rel="noreferrer">
                  (sjá lista hér)
                </a>
                . Með tengslum er átt við hvort þú eigir t.d. ættingja þar
                eignir eða önnur tengls.
              </>
            }
            error={form.errors.q5}
            value={form.values.q5}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              form.setFieldValue('q5', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
          {form.values.q5 === 'Já' && (
            <Inner>
              <NativeSelect
                data={HightRiskCountries}
                placeholder="Veldu land"
                label="Hvaða land?"
                error={form.errors.q5a}
                value={form.values.q5a}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  form.setFieldValue('q5a', e.currentTarget.value)
                }
                size="md"
                mb="md"
              />
            </Inner>
          )}
          <NativeSelect
            data={[
              'Undir 500.000 kr.',
              '500.000 kr. - 2.000.000 kr.',
              '2.000.000 kr. - 10.000.000 kr.',
              'Yfir 10.000.000 kr.',
            ]}
            placeholder="Veldu svar"
            label="Hvað gerir þú ráð fyrir að velta þín hjá mintum.is verði á mánaðargrundvelli?"
            error={form.errors.q6}
            value={form.values.q6}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              form.setFieldValue('q6', e.currentTarget.value)
            }
            size="md"
            mb="md"
          />
        </InputWrapper>
        <Button type="submit" size="md" loading={loading}>
          Senda svör
        </Button>
      </div>
    </form>
  );
}
