/* eslint-disable @next/next/no-img-element */
import { Center, Col, Grid, useMantineTheme } from '@mantine/core';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 800px;
  overflow: hidden;
  box-shadow: 0px 4px 16px rgba(0, 0, 60, 0.08);
  margin: 0 12px;
`;

export const LoginBook = ({
  left,
  right,
}: {
  left?: React.ReactNode;
  right?: React.ReactNode;
}) => {
  const theme = useMantineTheme();
  return (
    <Center sx={{ flex: 1 }}>
      <Container
        style={{
          backgroundColor: theme.white,
          color: theme.black,
          marginBottom: 16,
          marginTop: 16,
        }}>
        <Grid sx={{ flex: 1, minHeight: 400 }}>
          {left && (
            <Col
              span={12}
              md={6}
              sx={{
                backgroundColor:
                  theme.colorScheme === 'dark'
                    ? theme.colors.dark[9]
                    : '#f7f5f5',
                display: 'flex',
                alignItems: 'center',
                '@media (max-width: 992px)': {
                  display: 'none',
                },
              }}>
              {left}
            </Col>
          )}
          <Col span={12} md={!left ? 12 : 6}>
            {right}
          </Col>
        </Grid>
      </Container>
    </Center>
  );
};
