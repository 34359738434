import { Alert, Button, Divider, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { BsTelephone } from 'react-icons/bs';
import { config } from '../../config';
import { useAuthStore } from '../../lib/auth-store';
import { SafeUrlBar } from './safe-url-bar';

export function LoginWidget() {
  const router = useRouter();
  const auth = useAuthStore();

  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string>();
  const form = useForm({
    initialValues: {
      nationalId: '',
    },
    validate: {
      nationalId: (value) => {
        return !/^\d{10}$/.test(value);
      },
    },
  });

  const error = router.query.error;

  useEffect(() => {
    const loginSession = Cookies.get('login_session');
    if (!auth.accessToken && loginSession) {
      const session = JSON.parse(loginSession);
      auth.actions.setTokens(session);
      const tld = config.websiteUrl
        .replace(/https?:\/\//, '')
        .replace(/\/.*/, '')
        .split('.')
        .slice(-2)
        .join('.');
      const domain = tld.includes('.') ? `.${tld}` : tld.replace(/:\d+/, '');
      Cookies.remove('login_session', { domain });
    }
  }, [auth]);

  const onPhoneLoginSubmit = async (values: typeof form.values) => {
    setLoading(true);
    form.clearErrors();
    const nationalId = values.nationalId.replace(/\D/g, '');
    try {
      if (
        nationalId.length === 10 &&
        (await auth.actions.loginNationalId({
          nationalId,
          fullName: 'Gervimaður Jónsson',
        }))
      ) {
        form.reset();
      }
    } catch (err) {
      setSubmitError('Unable to authenticate');
      form.setFieldError('nationalId', true);
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        padding: 32,
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
        height: '100%',
      }}>
      <Title order={1} align="center" sx={{ marginBottom: 32 }}>
        Skráðu þig inn
      </Title>
      <Text color="dimmed" size="sm" align="center" sx={{ marginBottom: 8 }}>
        Gakktu úr skugga um að þú sért að á réttri slóð.
      </Text>
      <SafeUrlBar
        url={`${config.websiteUrl.replace(/^https?:\/\//, '')}/login`}
      />
      <Divider
        mt="lg"
        mb="lg"
        style={{ borderColor: '#F8F8F8', width: '100%' }}
      />
      <>
        {error && (
          <Alert title="Sign in error" color="red">
            {error}, vinsamlegast reynið aftur.
          </Alert>
        )}
        <Button
          component="a"
          mt="lg"
          size="md"
          radius="md"
          type="submit"
          fullWidth
          variant="gradient"
          loading={loading}
          gradient={{ from: 'blue', to: 'cyan' }}
          onClick={() => setLoading(true)}
          href={`${config.apiUrl}/auth/kenni`}>
          Nota rafræn skilríki
        </Button>
      </>
    </div>
  );
}
