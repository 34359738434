import type { AvatarProps } from '@mantine/core';
import { Avatar } from '@mantine/core';

interface UserAvatarProps extends AvatarProps {
  size: 'xl' | 'lg' | 'md' | 'sm' | 'xs' | number;
  fullName?: string | null;
  avatarUrl?: string | null;
}

function initials(str?: string) {
  if (!str) {
    return undefined;
  }
  const x = str.split(' ');
  return [x?.[0]?.[0], x?.[x.length - 1]?.[0]].join('');
}

export function UserAvatar(props: UserAvatarProps) {
  const { fullName, avatarUrl, size = 48, alt, color } = props;
  return (
    <Avatar
      src={avatarUrl || undefined}
      size={size}
      radius={size}
      alt={alt}
      color={color}>
      {avatarUrl ? undefined : initials(fullName || 'Unknown Name')}
    </Avatar>
  );
}
