import { Button, Text, TextInput, Title } from '@mantine/core';
import React, { useState } from 'react';
import {
  SecurityDocument,
  SecurityKeyResultDocument,
} from '../../graphql/generated';
import { client } from '../../lib/apollo-client';
import { setupSecurityKey } from '../../lib/security-key';

export function SetupSecurityKey({ onCancelClick }: { onCancelClick(): void }) {
  const [response, setResponse] = useState<string>();
  const [name, setName] = useState<string>('');
  const [state, setState] = useState<'start' | 'name' | 'complete'>('start');
  const [count, setCount] = useState(1);
  return (
    <div
      style={{
        padding: 32,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Title order={3} mb="sm">
        Setja upp öryggislykil
      </Title>
      {state === 'start' && (
        <Text>Settu öryggislykilinn í tækið þitt til þess að byrja.</Text>
      )}
      {state === 'name' && (
        <TextInput
          label="Name your security key"
          placeholder={`Key #${count}`}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          autoFocus
        />
      )}
      {state === 'complete' && (
        <Text>
          We have added your security key, you can now use it to sign in. We
          recommend to add at least two security keys for safety reasons.
        </Text>
      )}
      <div style={{ flex: 1 }} />
      <Button
        mt="lg"
        size="md"
        radius="md"
        fullWidth
        mb="lg"
        variant={state === 'complete' ? 'subtle' : undefined}
        color={state === 'complete' ? 'gray' : undefined}
        disabled={name.trim() === '' && state === 'name'}
        onClick={() => {
          if (state === 'start') {
            setupSecurityKey().then((res) => {
              setResponse(res);
              setState('name');
            });
          }
          if (state === 'name') {
            client
              .mutate({
                mutation: SecurityKeyResultDocument,
                variables: {
                  type: 'attestation',
                  response,
                  name,
                },
              })
              .then(() => {
                setResponse(undefined);
                // refetch security keys
                client
                  .query({
                    query: SecurityDocument,
                  })
                  .then(() => {
                    setName('');
                    setState('complete');
                  });
              });
          }
          if (state === 'complete') {
            setCount((c) => c + 1);
            setupSecurityKey().then((res) => {
              setResponse(res);
              setState('name');
            });
          }
        }}>
        {state === 'start' && 'Halda áfram'}
        {state === 'name' && 'Save security key'}
        {state === 'complete' && 'Add another key'}
      </Button>
      <Button
        size="md"
        radius="md"
        type="submit"
        fullWidth
        variant={state === 'complete' ? undefined : 'subtle'}
        color={state === 'complete' ? undefined : 'gray'}
        onClick={onCancelClick}>
        {state === 'complete' ? 'Loka' : 'Hætta við'}
      </Button>
    </div>
  );
}
