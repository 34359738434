import { Badge, Text, useMantineTheme } from '@mantine/core';
import { MdLock } from 'react-icons/md';

export function SafeUrlBar({ url }: { url: string }) {
  const theme = useMantineTheme();
  return (
    <Badge
      size="xl"
      sx={{
        textTransform: 'inherit',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '0px',
        paddingLeft: 48,
        paddingRight: 48,
        '@media (max-width: 700px)': {
          paddingLeft: 30,
          paddingRight: 30,
        },
      }}
      color="gray"
      leftSection={<MdLock color={theme.colors.green[6]} />}>
      <Text color="green" component="span">
        https://
      </Text>
      {url}
    </Badge>
  );
}
