/* eslint-disable @next/next/no-img-element */
import { useDebouncedValue } from '@mantine/hooks';
import { useRouter } from 'next/dist/client/router';
import React, { useEffect, useMemo, useState } from 'react';
import { AdvancedRiskWidget } from '../components/boarding-widgets/advanced-risk-estimation-widget';
import { CompleteSignupWidget } from '../components/boarding-widgets/complete-signup-widget';
import { LoginBook } from '../components/boarding-widgets/login-book';
import { LoginWidget } from '../components/boarding-widgets/login-widget';
import { MultiFactorWidget } from '../components/boarding-widgets/multi-factor-widget';
import { MultiFactorSetupWidget } from '../components/boarding-widgets/multifactor-setup-widget';
import { RiskWidget } from '../components/boarding-widgets/risk-estimation-widget';
import { UserProfileWidget } from '../components/boarding-widgets/user-profile-widget';
import { GridLoader } from '../components/loader/grid-loader';
import { useProfileQuery } from '../graphql/generated';
import { authStore, MultiFactorStatus, useAuthStore } from '../lib/auth-store';
import { fbq, gtag } from '../lib/metrics';

enum LoginState {
  INITIALIZING = 'INITIALIZING',
  LOGIN = 'LOGIN',
  COMPLETE_ACCOUNT = 'COMPLETE_ACCOUNT',
  SETUP_MULTIFACTOR = 'SETUP_MULTIFACTOR',
  CONFIRM_MULTIFACTOR = 'CONFIRM_MULTIFACTOR',
  LOGGED_IN = 'LOGGED_IN',
  KYC_SATUS_BASIC_REQUIRED = 'BASIC_REQUIRED',
  KYC_STATUS_ADVANCED_REQUIRED = 'ADVANCED_REQUIRED',
}

const Illustrations = {
  LOGIN: (
    <img
      src="/illustrations/mobile-login-amico.svg"
      alt="Login illustration"
      style={{ minWidth: '100%', padding: 32 }}
    />
  ),
  COMPLETE_SIGNUP: (
    <img
      src="/illustrations/two-factor-authentication-amico.svg"
      alt="Multi factor illustration"
      style={{ minWidth: '100%', padding: 32 }}
    />
  ),
  LOADING: (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: '100%',
      }}>
      <GridLoader width={48} color="#aaa" />
    </div>
  ),
};

export default function Login() {
  const auth = useAuthStore();
  const router = useRouter();
  const user = useProfileQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: auth.accessToken ? 'cache-first' : 'network-only',
    errorPolicy: 'ignore',
    partialRefetch: true,
    variables: {
      loggedIn: auth.loggedIn,
      accessToken: auth.accessToken,
    } as unknown as Record<string, never>,
    skip: !auth.accessToken,
  });

  const [left, setLeft] = useState<React.ReactNode>(Illustrations.LOGIN);
  const [right, setRight] = useState<React.ReactNode>(undefined);
  const [loginState, setLoginState] = useState(LoginState.INITIALIZING);
  const [loading, setLoading] = useState(true);
  const [loadingDebounced] = useDebouncedValue(loading, 330);

  useEffect(() => {
    // initial loading state
    if (authStore.persist?.hasHydrated?.()) {
      setLoading(false);
    }
    const hydrationListener = authStore.persist.onFinishHydration(() => {
      setLoading(false);
      hydrationListener();
    });
  }, []);

  useEffect(() => {
    if (!auth.accessToken) {
      return setLoginState(LoginState.LOGIN);
    }

    if (!user.data && user.loading) {
      return setLoading(true);
    }

    setLoading(false);

    if (!user.data?.me?.termsAgreedAt) {
      return setLoginState(LoginState.COMPLETE_ACCOUNT);
    }

    if (!user.data?.me?.securityOnboardedAt) {
      return setLoginState(LoginState.SETUP_MULTIFACTOR);
    }

    if (Object.values(auth.mfa || {}).includes(MultiFactorStatus.REQUIRED)) {
      return setLoginState(LoginState.CONFIRM_MULTIFACTOR);
    }

    if (user.data?.me?.kycStatus === 'BASIC_REQUIRED') {
      return setLoginState(LoginState.KYC_SATUS_BASIC_REQUIRED);
    }

    if (user.data?.me?.kycStatus === 'ADVANCED_REQUIRED') {
      return setLoginState(LoginState.KYC_STATUS_ADVANCED_REQUIRED);
    }

    setLoginState(LoginState.LOGGED_IN);
  }, [user.data, user.loading, auth]);

  useEffect(() => {
    switch (loginState) {
      case LoginState.LOGIN:
        setLeft(Illustrations.LOGIN);
        setRight(<LoginWidget />);
        break;
      case LoginState.COMPLETE_ACCOUNT:
        setLeft(<UserProfileWidget user={user.data?.me} />);
        setRight(<CompleteSignupWidget />);
        break;
      case LoginState.SETUP_MULTIFACTOR:
        setLeft(Illustrations.COMPLETE_SIGNUP);
        setRight(<MultiFactorSetupWidget />);
        break;
      case LoginState.CONFIRM_MULTIFACTOR:
        setLeft(<UserProfileWidget user={user.data?.me} />);
        setRight(<MultiFactorWidget />);
        break;
      case LoginState.LOGGED_IN:
        setRight(Illustrations.LOADING);
        break;
      case LoginState.KYC_SATUS_BASIC_REQUIRED:
        setLeft(null);
        setRight(<RiskWidget />);
        break;
      case LoginState.KYC_STATUS_ADVANCED_REQUIRED:
        setLeft(null);
        setRight(<AdvancedRiskWidget />);
        break;
    }

    if (loginState === LoginState.LOGGED_IN) {
      router.push('/dashboard');
      gtag.event({
        action: 'login',
        category: 'Login',
        label: user.data?.me.fullName || 'undefined',
      });
      fbq.event('CompleteRegistration');
      setLoginState(LoginState.INITIALIZING);
      // setTimeout(() => {
      // }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState]);

  return useMemo(
    () => (
      <LoginBook
        left={left}
        right={loadingDebounced ? Illustrations.LOADING : right}
      />
    ),
    [left, right, loadingDebounced]
  );
}

Login.options = {
  layout: {
    background: 'dark',
    hideHeaderAuth: true,
  },
};
