import { Button, Space, Text, TextInput, Title } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { MultiFactorType } from '../../graphql/generated';
import {
  authStore,
  MultiFactorStatus,
  useAuthStore,
} from '../../lib/auth-store';
import { readSecurityKey } from '../../lib/security-key';
import { MultiFactorInput } from './multi-factor-input';

const SecurityKeyInput = ({
  status,
  onlyOne,
}: {
  status: MultiFactorStatus;
  onlyOne: boolean;
}) => {
  const isVerified = status === MultiFactorStatus.VERIFIED;
  const [message, setMessage] = useState<string>();
  const readKey = () => {
    readSecurityKey()
      .then((success) => {
        if (success) {
          authStore.getState().actions.refreshTokens({ logoutOnError: false });
        } else {
          setMessage('Lykill ekki þekktur');
        }
      })
      .catch((err: Error) => {
        setMessage(err.message.replace(/(?:See: )?http.*/, '').trim());
      });
  };
  useEffect(() => {
    if (onlyOne) {
      readKey();
    }
  }, []);
  return (
    <TextInput
      label="Öryggislykill"
      readOnly={true}
      placeholder={isVerified ? '(staðfestur)' : message}
      sx={{
        input: { fontWeight: 600 },
        label: {
          textTransform: 'uppercase',
          fontSize: 13,
          letterSpacing: '0.04em',
          fontWeight: 600,
        },
      }}
      rightSectionWidth={80}
      rightSection={
        <Button
          compact
          sx={{ width: 70, minHeight: 28 }}
          size="xs"
          variant="light"
          onClick={() => {
            readKey();
          }}
          disabled={isVerified}>
          Lesa lykil
        </Button>
      }
    />
  );
};

export function MultiFactorWidget() {
  const auth = useAuthStore();
  const onCancelClick = useCallback(() => {
    auth.actions.logout();
  }, [auth.actions]);

  const authMfa = ((auth.mfa as unknown) || {}) as Record<
    MultiFactorType,
    MultiFactorStatus
  >;

  const methods = Object.entries(authMfa)
    .filter(([k, v]) => v >= 0)
    .map(([k, v]) => k as MultiFactorType);
  const isSecurityKey = methods.includes(MultiFactorType.SecurityKey);
  const isApp = methods.includes(MultiFactorType.App);
  const isEmail = methods.includes(MultiFactorType.Email);
  const isSMS = methods.includes(MultiFactorType.Sms);
  const isMFA = (isSecurityKey && auth.skotp === true) || !isSecurityKey;

  return (
    <div
      style={{
        padding: 32,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Title order={3}>Fjölþátta auðkenning</Title>
      <Text>Aflæstu aðganginum með fjölþátta auðkenningu.</Text>
      <Space h="xl" />
      {isSecurityKey && (
        <SecurityKeyInput
          onlyOne={!isMFA}
          status={authMfa[MultiFactorType.SecurityKey]}
        />
      )}
      {isMFA && isApp && (
        <MultiFactorInput auth={auth} autoFocus type={MultiFactorType.App} />
      )}
      {isMFA && isEmail && (
        <MultiFactorInput
          auth={auth}
          autoFocus
          type={MultiFactorType.Email}
          key="email"
        />
      )}
      {isMFA && isSMS && (
        <MultiFactorInput auth={auth} autoFocus type={MultiFactorType.Sms} />
      )}
      <Space sx={{ flex: 1 }} />
      <Button
        size="md"
        radius="md"
        type="submit"
        fullWidth
        variant="subtle"
        color="gray"
        onClick={onCancelClick}>
        Hætta við
      </Button>
    </div>
  );
}
