import {
  Alert,
  Button,
  Checkbox,
  Space,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useCallback, useState } from 'react';
import { CompleteSignupDocument } from '../../graphql/generated';
import { client } from '../../lib/apollo-client';
import { gist } from '../../lib/metrics';

export function CompleteSignupWidget() {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      email: '',
      agreed: false,
      marketing: false,
    },
    validate: {
      email: (str) => !/^[\w.%+-]+@[A-Z\d.-]+\.[A-Z]{2,}$/i.test(str),
      agreed: (value: boolean) => !value,
    },
  });

  type FormValues = typeof form.values;

  const onSubmit = useCallback(async (values: FormValues) => {
    if (values.marketing) {
      gist.identify({
        allow_marketing_emails: true,
      });
    }
    setLoading(true);
    try {
      await client.mutate({
        mutation: CompleteSignupDocument,
        variables: {
          email: values.email,
        },
      });
    } catch (err) {
      const error = !!(err as any).graphQLErrors?.find(
        (e: any) => e.message === 'EMAIL_ALREADY_EXISTS'
      );
      if (error) {
        form.setFieldError('email', 'Þetta netfang er þegar í notkun.');
      } else {
        form.setFieldError('email', 'Eitthvað fór úrskeiðis. Reyndu aftur.');
      }
    }
    setLoading(false);
    return null;
  }, []);

  return (
    <div style={{ padding: 32, maxWidth: 400 }}>
      <Title order={2} sx={{ marginBottom: 8 }}>
        Setja upp á reikning
      </Title>
      <Text>
        Vinsamlegast gefðu upp netfang til þess að klára uppsetningu, við sendum
        þér svo tölvupóst á netfangið til staðfestingar.
      </Text>
      {form.errors.agreed ? (
        <Alert color="red" mt="sm" mb="sm">
          Þú verður að staðfesta skilmálana okkar.
        </Alert>
      ) : (
        <Space h={72} />
      )}
      <form onSubmit={form.onSubmit(onSubmit)}>
        <TextInput
          label="Netfang"
          type="email"
          error={
            form.errors.email === true
              ? 'Vinsamlegast sláðu inn gilt netfang'
              : form.errors.email
          }
          value={form.values.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            form.setFieldValue('email', e.currentTarget.value)
          }
          size="md"
          radius="md"
          placeholder="þitt@netfang.is"
          autoFocus
          sx={{
            input: { fontWeight: 600 },
            label: {
              textTransform: 'uppercase',
              fontSize: 13,
              letterSpacing: '0.04em',
              fontWeight: 600,
            },
          }}
        />
        <Space h={40} />
        <Checkbox
          label={
            <>
              Ég samþykki{' '}
              <a
                style={{
                  color: '#0055ee',
                  borderBottom: '1px solid #0055ee',
                  textDecoration: 'none',
                }}
                href="/terms"
                target="_blank">
                skilmála
              </a>{' '}
              á þjónustum.
            </>
          }
          checked={form.values.agreed}
          size="md"
          color="blue"
          onChange={(event) =>
            form.setFieldValue('agreed', event.currentTarget.checked)
          }
        />
        <Space h={16} />
        <Checkbox
          label={
            <>
              Leyfðu okkur að senda þér tölvupóst um nýjungar og viðbætur í
              kerfinu.
            </>
          }
          checked={form.values.marketing}
          size="md"
          color="blue"
          onChange={(event) =>
            form.setFieldValue('marketing', event.currentTarget.checked)
          }
        />
        <Space h={40} />
        <Button
          size="md"
          radius="md"
          type="submit"
          fullWidth
          variant="gradient"
          gradient={{ from: 'blue', to: 'cyan' }}
          loading={loading}>
          Halda áfram
        </Button>
      </form>
    </div>
  );
}
