import { Button, Checkbox, Space, Text, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRouter } from 'next/router';
import QRCode from 'qrcode.react';
import React, { useCallback, useEffect, useState } from 'react';
import { VscCheck } from 'react-icons/vsc';
import styled from 'styled-components';
import {
  MultiFactorType,
  SetupMultiFactorDocument,
  UpdateSecurityPreferencesDocument,
  useSecurityQuery,
  VerifyMultiFactorDocument,
} from '../../graphql/generated';
import { client } from '../../lib/apollo-client';
import { useAuthStore } from '../../lib/auth-store';
import { SetupSecurityKey } from './setup-security-key';

const OtpCheckbox = styled(Checkbox)``;
OtpCheckbox.defaultProps = {
  color: 'green',
  size: 'md',
  sx: { input: { borderRadius: 16 } },
  icon: ({ className }) => <VscCheck className={className} />,
  wrapperProps: {
    style: {
      border: '1px solid rgba(128,128,128,0.2)',
      borderRadius: 6,
      padding: 12,
      paddingLeft: 16,
      paddingBottom: 16,
      marginBottom: 8,
    },
  },
};

export function MultiFactorSetupWidget({ onComplete }: any) {
  const auth = useAuthStore();
  const [isSetup, setIsSetup] = useState(false);
  const [appCodeLoading, setAppCodeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mfa, setMfa] = useState<{ keyUri: string; mnemonicWords: string[] }>();
  const [isSecurityKey, setIsSecurityKey] = useState(false);
  const router = useRouter();
  const auths = useSecurityQuery();
  const registeredMethods = auths.data?.me?.auths || [];

  const form = useForm({
    initialValues: {
      withdrawal: true,
      securityKey: false,
      code: '',
      APP: false,
      EMAIL: false,
      SMS: false,
      SECURITY_KEY: false,
    },
  });

  const onCancelClick = useCallback(() => {
    setIsSetup(false);
    setIsSecurityKey(false);
  }, []);

  const onAppCodeSubmit = useCallback(async () => {
    if (form.values.code.length === 6) {
      setAppCodeLoading(true);
      const res = await client.mutate({
        mutation: VerifyMultiFactorDocument,
        variables: { code: form.values.code },
      });
      if (res.data.verifyMultiFactor) {
        setIsSetup(false);
        form.setFieldValue('APP', true);
      } else {
        form.setFieldError('code', true);
      }
      setAppCodeLoading(false);
    }
  }, [form]);

  const onSubmit = useCallback(async () => {
    const types = [];
    if (form.values.APP) types.push('APP');
    if (form.values.EMAIL) types.push('EMAIL');
    if (form.values.SMS) types.push('SMS');
    if (form.values.SECURITY_KEY) types.push('SECURITY_KEY');
    setLoading(true);
    await client.mutate({
      mutation: UpdateSecurityPreferencesDocument,
      variables: {
        types,
      },
    });
    await new Promise((r) => setTimeout(r, 1000));
    await auth.actions.refreshTokens({ logoutOnError: false });
    setLoading(false);
  }, [auth.actions, router, form.values]);

  useEffect(() => {
    if (isSetup) {
      form.setFieldValue('APP', false);
      client
        .mutate({
          mutation: SetupMultiFactorDocument,
        })
        .then((res) => setMfa(res.data.setupMultiFactor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetup]);

  const updateDefaults = () => {
    const hasApp =
      (auths.data?.me?.auths || []).filter(
        (auth) => auth.type === MultiFactorType.App
      ).length > 0;
    const hasSecurityKey =
      (auths.data?.me?.auths || []).filter(
        (auth) => auth.type === MultiFactorType.SecurityKey
      ).length > 0;

    if (!hasApp) {
      form.setFieldValue('APP', false);
    }
    if (!hasSecurityKey) {
      form.setFieldValue('SECURITY_KEY', false);
    }
  };

  useEffect(() => {
    updateDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetup, isSecurityKey]);

  useEffect(() => {
    if (form.values.code.length === 6) {
      onAppCodeSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.code]);

  if (isSecurityKey) {
    return <SetupSecurityKey onCancelClick={onCancelClick} />;
  }

  if (isSetup) {
    return (
      <div style={{ padding: 32 }}>
        <Title order={3}>Setja upp app auðkenningu</Title>
        <Text>
          Skannaðu QR kóðann með forriti sem styður one-time-password
          auðkenningu.
        </Text>
        <form
          style={{ marginBottom: 16, marginTop: 16 }}
          onSubmit={form.onSubmit(onAppCodeSubmit)}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 180,
            }}>
            {mfa?.keyUri && (
              <QRCode size={128} renderAs="svg" value={mfa?.keyUri} />
            )}
          </div>
          <TextInput
            label="6-Stafa kóði"
            type="tel"
            error={
              form.errors.code && 'Útrunnið eða ógildur kóði, reyndu aftur.'
            }
            value={form.values.code}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              form.setFieldValue(
                'code',
                e.currentTarget.value.replace(/\D/g, '').substring(0, 6)
              )
            }
            size="md"
            radius="md"
            autoFocus
            disabled={appCodeLoading}
            sx={{
              input: { fontWeight: 600 },
              label: {
                textTransform: 'uppercase',
                fontSize: 13,
                letterSpacing: '0.04em',
                fontWeight: 600,
              },
            }}
            mb="lg"
          />
          <Button
            type="submit"
            size="md"
            radius="md"
            fullWidth
            onClick={onCancelClick}>
            Staðfesta
          </Button>
        </form>
        <Button
          size="md"
          radius="md"
          fullWidth
          variant="subtle"
          color="gray"
          onClick={onCancelClick}>
          Hætta við
        </Button>
      </div>
    );
  }

  const isNone =
    !form.values.APP &&
    !form.values.EMAIL &&
    !form.values.SMS &&
    !form.values.SECURITY_KEY;

  return (
    <div style={{ padding: 32 }}>
      <Title order={2}>Eitt að lokum</Title>
      <Text>
        Við mælum eindregið með því að virkja eina eða fleiri fjölþátta
        auðkenningar þegar þú skráir þig inn.
      </Text>
      <form style={{ marginTop: 16 }} onSubmit={form.onSubmit(onSubmit)}>
        <OtpCheckbox
          onClick={() => {
            if (
              !form.values.SECURITY_KEY &&
              !registeredMethods.find(
                (r) => r.type === MultiFactorType.SecurityKey
              )
            ) {
              setIsSecurityKey(true);
            }
          }}
          label={
            <>
              <Text size="sm" weight={600}>
                Öryggislykill
              </Text>
              <Text size="xs">YubiKey, Titan eða aðrir FIDO2 lyklar</Text>
              <Text color="green" size="xs" weight="bold">
                Besta öryggið
              </Text>
            </>
          }
          checked={form.values.SECURITY_KEY}
          onChange={(event) =>
            form.setFieldValue('SECURITY_KEY', event.currentTarget.checked)
          }
        />
        <OtpCheckbox
          onClick={() => {
            if (
              !form.values.APP &&
              !registeredMethods.find((r) => r.type === MultiFactorType.App)
            ) {
              setIsSetup(true);
            }
          }}
          label={
            <>
              <Text size="sm" weight={600}>
                App
              </Text>
              <Text size="xs">Google Auhenticator og önnur slík öpp</Text>
              <Text color="green" size="xs" weight="bold">
                Betra öryggi
              </Text>
            </>
          }
          checked={form.values.APP}
          onChange={(event) =>
            form.setFieldValue('APP', event.currentTarget.checked)
          }
        />
        <OtpCheckbox
          label={
            <>
              <Text size="sm" weight={600}>
                Tölvupóstur
              </Text>
              <Text size="xs">8-stafa öryggiskóði sendur í tölvupósti</Text>
              <Text color="green" size="xs" weight="bold">
                Gott öryggi
              </Text>
            </>
          }
          checked={form.values.EMAIL}
          onChange={(event) =>
            form.setFieldValue('EMAIL', event.currentTarget.checked)
          }
        />
        <OtpCheckbox
          label={
            <>
              <Text size="sm" weight={600}>
                Textaskilaboð
              </Text>
              <Text size="xs">6-stafa öryggiskóði sendur í SMS</Text>
              <Text color="yellow" size="xs" weight="bold">
                Öryggi
              </Text>
            </>
          }
          checked={form.values.SMS}
          onChange={(event) =>
            form.setFieldValue('SMS', event.currentTarget.checked)
          }
        />
        <Space h={40} />
        <Button
          size="md"
          radius="md"
          type="submit"
          fullWidth
          loading={loading}
          variant={isNone ? 'outline' : undefined}>
          {isNone ? 'Sleppa í bili' : 'Halda áfram'}
        </Button>
      </form>
    </div>
  );
}
